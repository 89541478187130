/**
 * Получить из переменной окружения sso client id
 */
export function getSsoClientId(): string {
  const { origin } = window.location;

  if (origin.includes('localhost')) {
    const clientId = process.env['SSO_CLIENT_ID'];
    if (!clientId) {
      throw new Error('process.env.SSO_CLIENT_ID is undefined!');
    }

    return clientId;
  }

  if (origin.includes('app.pwi.pushwoosh')) {
    return 'APP_FRONT_PWI';
  }

  if (origin.includes('app.omo-tech')) {
    return 'OMO_FRONT';
  }

  return 'APP_FRONT';
}

export function getPhpApiOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost') || origin.includes('app.omo-tech')) {
    return 'https://go.pushwoosh.com';
  }

  return origin.replace('app.', 'go.');
}

export function getSsoPublicOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost')) {
    return 'https://sso.pushwoosh.com';
  }

  return origin.replace('app.', 'sso.');
}

export function getGrpcApiOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost') || origin.includes('app.omo-tech')) {
    return 'https://rpc-api-grpcweb.svc-nue.pushwoosh.com';
  }

  if (origin.includes('app.pushwoosh')) {
    return origin.replace('app.', 'rpc-api-grpcweb.svc-nue.');
  }

  return origin.replace('app.', 'rpc-api-grpcweb.');
}

export function getGrpcBillingApiOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost') || origin.includes('app.omo-tech')) {
    return 'https://billing-grpcweb.svc-nue.pushwoosh.com';
  }

  if (origin.includes('app.pushwoosh')) {
    return origin.replace('app.', 'billing-grpcweb.svc-nue.');
  }

  return origin.replace('app.', 'billing-grpcweb.');
}

export function getGrpcJourneyApiOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost') || origin.includes('app.omo-tech')) {
    return 'https://journey-api-grpc.svc-nue.pushwoosh.com';
  }

  if (origin.includes('app.pushwoosh')) {
    return origin.replace('app.', 'journey-api-grpc.svc-nue.');
  }

  return origin.replace('app.', 'journey-api-grpc.');
}

export function getUserActionsApiOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost') || origin.includes('app.omo-tech')) {
    return 'https://user-action.svc-nue.pushwoosh.com';
  }

  if (origin.includes('app.pushwoosh')) {
    return origin.replace('app.', 'user-action.svc-nue.');
  }

  return origin.replace('app.', 'user-action.');
}
